<template>
  <div class="userInfo">
    <!-- ↓ 手机端 样式 -->
    <div class="user" v-if="!pagination">
      <userInfo-mobile :userData="userData"></userInfo-mobile>
    </div>
    <!-- ↓ PC端样式 -->
    <div class="inner" v-if="pagination">
      <!-- 中心 -->
      <p class="content_title">基本信息</p>
      <p class="content_tip"></p>
      <div class="content_form">
        <div class="content_list">
          <label class="content_label">头像</label>
          <div v-if="dataLoad" class="skeleton-item"></div>
          <div v-if="!dataLoad" class="content_label_controls content_upload">
            <div class="form_upload_left">
              <div class="inputIcon avatar">
                <div class="demo-image__preview">
                  <div class="imageDefault" v-if="!userAvatar">{{ username.substring(0, 2) }}</div>
                  <el-image class="image" v-if="userAvatar" :src="userAvatar" :preview-src-list="srcList">
                  </el-image>
                </div>
                <el-upload list-type="picture-card" :action="uploadUrl + '/update-avatar'" :show-file-list="false"
                  :headers="token" :on-preview="handlePictureCardPreview" :on-success="handleAvatarSuccess"
                  :on-error="handleAvatarError" :before-upload="beforeAvatarUpload" :accept="acceptTypes">
                  <img v-if="imageUrl" :src="imageUrl" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </div>
              <!-- <p class="content_form_tip">请上传高清图片作为平台头像。</p> -->
              <!-- <button @click="uploadFile">上传文件</button><input type="file" ref="fileInput" style="display: none"
                accept="image/*"> -->
            </div>
            <!-- <div class="form_upload_line"></div> -->
            <div class="form_upload_right">
            </div>
          </div>
        </div>
        <div class="content_list">
          <label class="content_label">{{ $t("昵称") }}</label>
          <div v-if="dataLoad" class="skeleton-item"></div>
          <div v-if="!dataLoad" class="content_label_controls content_upload">
            <div class="form_upload_left">
              <div class="forms">
                <input type="text" class="content_label_input" @focus="nameActive = true" @blur="nameBlur()"
                  v-model="username" :placeholder="nameActive ? '' : $t('昵称')">
                <button class="content_label_btn" @click="save()">{{ $t("保存") }}</button>
              </div>
              <p class="content_form_tip">需在2-20个字符之间（应尽量简短）</p>
            </div>
          </div>
        </div>
        <div class="content_list">
          <label class="content_label">{{ $t("电子邮箱") }}</label>
          <div v-if="dataLoad" class="skeleton-item"></div>
          <div v-if="!dataLoad" class="content_label_controls content_upload">
            <div class="form_upload_left">
              <div class="forms">
                <input type="text" class="content_label_input" @focus="emailActive = true" @blur="emailBlur()"
                  v-model="userEmail" :placeholder="emailActive ? '' : $t('电子邮箱')">
                <button class="content_label_btn" @click="bindEmail()">{{ emailStatus ? $t("修改") : $t("绑定") }}</button>
              </div>
              <p class="content_form_tip">{{ $t("绑定或修改邮箱") }}</p>
            </div>
          </div>
        </div>
        <div class="content_list">
          <label class="content_label">{{ $t("手机") }}</label>
          <div v-if="dataLoad" class="skeleton-item"></div>
          <div v-if="!dataLoad" class="content_label_controls content_upload">
            <div class="form_upload_left">
              <div class="forms">
                <input type="text" class="content_label_input" @focus="phoneActive = true" @blur="phoneBlur()"
                  v-model="userPhone" :placeholder="phoneActive ? '' : $t('手机')">
                <button class="content_label_btn" @click="bindPhone()">{{ phoneStatus ? $t("修改") : $t("绑定") }}</button>
              </div>
              <p class="content_form_tip">{{ $t("绑定或修改手机号") }}</p>
            </div>
          </div>
        </div>
        <!-- <div class="content_list">
          <label class="content_label">{{ $t('AI员工') }}</label>
          <div v-if="dataLoad" class="skeleton-item"></div>
          <div v-if="!dataLoad" class="content_label_controls content_upload">
            <div class="form_upload_left">
              <div class="forms">
                <input type="text" class="content_label_input " :class="bases.length ? 'disabled' : ''"
                  :disabled="bases.length ? true : false" @focus="asstIdActive = true" @blur="asstIdBlur()"
                  v-model="emp_id" :placeholder="asstIdActive ? '' : $t('AI员工ID')">
                <button class="content_label_btn" @click="handleSwitchChange(emp_id, bases.length ? false : true)">
                  {{ bases.length ? $t('解绑') : $t('绑定') }}</button>
              </div>
              <div class="caption">{{ $t("请前往") }}<span class="mouseHead" @click="toGPTadmin">{{
                $t("奇妙人AI员工管理平台") }}</span>{{ $t("获取员工ID") }}</div>
              <div class="caption">请联系奇妙人运营人员获取AI员工ID</div>
            </div>
          </div>
        </div>
        <div class="content_list">
          <label class="content_label">{{ $t("微信公众号") }}</label>
          <div v-if="dataLoad" class="skeleton-item"></div>
          <div v-if="!dataLoad" class="content_label_controls content_upload">
            <div class="form_upload_left">
              <div class="forms">
                <input type="text" class="content_label_input " :class="app_id ? 'disabled' : ''" name="username"
                  :disabled="app_id ? true : false" @focus="officalIdActive = true" @blur="officalIdBlur()"
                  v-model="officialIdValue" :placeholder="officalIdActive ? '' : $t('AppID')">
                <button class="content_label_btn " :class="app_id ? 'content_label_btn_default' : ''"
                  @click="handleSwitchChangeAppID(officialIdValue, app_id ? false : true)">
                  {{ app_id ? $t('已授权') : $t('授权') }}</button>
              </div>
              <div class="caption">{{ $t("请前往") }}<span class="mouseHead" @click="toWeChatSetting">{{
                $t("公众号后台获取APPID") }}</span>{{ $t("并") }}<span>{{ $t("扫码授权") }}</span></div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <!-- 点击图标预览 模块 -->
    <div class="preview inner" v-if="pagination">
      <p class="title content_title">点击图标预览</p>
      <div class="link">
        <img class="logo mouseHead" src="../../../../../src/assets/icon/otherMod.png"
          @click="$router.push('/ai-voice')" />
      </div>
    </div>
  </div>
</template>

<script>
import { create } from "sortablejs";
import { userInfo, updateUserAlias, updateBaseUsage, bindingOfficial, checkUser, updateAvatar, wechatQrcode, wechatQrcodeCallback } from "../../../../config/api.js"
import { getBaseIp } from "../../../../config/request.js"
import userInfoMobile from "./userInfo-mobile.vue"

export default {
  name: "userInfo",
  components: {
    userInfoMobile
  },
  data() {
    return {
      link: '', // 跳转链接
      useNum: "",
      pagination: true,
      linkList: [
        { icon: "icon-userinfo1", name: this.$t("我的信息"), path: "userInfo" },
        { icon: "icon-recharge", name: this.$t("充值记录"), path: "payForm" },
        { icon: "icon-shiyongjilu", name: this.$t("使用记录"), path: "useForm" },
        { icon: "icon-yue", name: '剩余用量：' , path: "recharge" },
        { icon: "icon-logout", name: this.$t("退出登录"), path: "/" }
      ],
      username: "",
      userEmail: " ",
      userPhone: 0,
      nameActive: false,
      emailActive: false,
      phoneActive: false,
      asstIdActive: false,
      officalIdActive: false,
      emp_id: null,
      userAvatar: "",
      srcList: [this.userAvatar],
      nameMsg: "",
      emailMsg: "",
      phoneMsg: "",
      dataLoad: true,
      navsList: false, // 导航栏默认隐藏
      dialogImageUrl: '',
      dialogVisible: false,
      uploadUrl: getBaseIp(), // 上传头像的url
      token: { "Authorization": JSON.parse(localStorage.getItem("userData")).token },
      imageUrl: "",
      acceptTypes: 'image/*', // 允许上传图片文件
      /**AI 员工有无权限 */
      // asst_enable: false,// AI员工权限
      bases: [],
      app_id: "", // 已经绑定的微信公众号的值
      officialIdValue: "", // 微信公众号输入框的值
      emailStatus: true,//邮箱输入框状态
      phoneStatus: true,//手机输入框状态
      userData: {},
    };
  },
  created() {
    // 白屏
    // this.token = { "Authorization": JSON.parse(this.$verifyToken()).token }
  },
  mounted() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
    this.getuserInfo();
  },
  methods: {
    handleResize() {
      this.pagination = window.innerWidth > 768;
    },
    getuserInfo() {
      userInfo().then(res => {
        this.dataLoad = false;
        this.username = res.data.nickname || res.data.username;
        this.userEmail = res.data.email;
        this.emailStatus = !(!(res.data.email))
        this.userPhone = res.data.phone;
        this.phoneStatus = !(!(res.data.phone))
        this.userAvatar = res.data.avatar;
        this.bases = res.data.bases;
        this.officialIdValue = res.data.app_id
        this.app_id = res.data.app_id
        // this.asst_enable = res.data.asst_enable;
        // console.log('this.bases.length',this.bases.length)
        if (res.data.bases.length) {
          this.emp_id = res.data.bases[0].emp_id
        }
        // 传值给子组件userInfo-mobile
        this.userData = res.data
        // 传值给pc和移动端左侧导航栏
        this.$store.dispatch('updateBalance', res.data.balance)
      });
    },
    clearQuery() {
      // 获取当前URL
      const currentUrl = window.location.href;

      // 创建一个URL对象
      const url = new URL(currentUrl);

      // 删除特定的查询参数
      url.searchParams.delete('auth_code');
      url.searchParams.delete('expires_in');

      // 使用 history.replaceState 来更新URL
      window.history.replaceState({}, '', url);

      // 触发Vue Router更新
      this.$router.replace({
        path: this.$route.path,
        query: Object.fromEntries(url.searchParams.entries())
      });
    },
    getWechatQrcodeCallback(auth_code, expires_in) {
      let data = {
        auth_code, expires_in
      }
      wechatQrcodeCallback(data)
        .then((res) => {
          if (res.data.status === 'success') {
            this.getuserInfo() // 获取用户信息
            this.clearQuery() // 清除URL中的参数
          }
        })
    },
    handleCollapseEvent(value) {
      this.navsList = value
    },
    save() {
      let params = {
        alias: this.username
      };
      if (!params.alias) return this.$message({ showClose: true, message: this.$t('姓名是必填字段'), type: "error" });
      updateUserAlias(params)
        .then((res) => {
          if (res.data.status === 'success') {
            this.$message({ showClose: true, message: this.$t('保存成功'), type: "success" });
          }
        })
        .catch(() => { });
    },
    handleAvatarSuccess(res, file, fileList) {
      this.$message({ showClose: true, message: this.$t('头像上传成功'), type: "success" });
      this.getuserInfo();
    },
    handleAvatarError() {
      this.$message({ showClose: true, message: this.$t('头像上传失败'), type: "error" });
    },
    beforeAvatarUpload(file) {
      // const isJPG = file.type === 'image/jpeg';
      const imgSize = file.size / 1024 / 1024 < 0.5;

      // if (!isJPG) {
      //   this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      if (!imgSize) {
        this.$message.error(this.$t('上传头像图片大小不能超过点5MB'));
      }
      return imgSize;
    },
    uploadFile() {
      const fileInput = this.$refs.fileInput;
      fileInput.click();
      fileInput.removeEventListener('change', this.handleImageUpload); // 移除之前的change事件监听器
      fileInput.addEventListener('change', this.handleImageUpload);
    },
    handleImageUpload(event) {
      const file = event.target.files[0];
      if (file && file.type.includes('image/')) {
        if (file.size > 2 * 1024 * 1024) {
          return this.$message.error(this.$t('上传头像图片大小不能超过2MB'));
        }
        if (file.size <= 2 * 1024 * 1024) {
          const formData = new FormData();
          formData.append('file', file);

          // 发送文件上传请求
          // 例如使用axios发送POST请求
          updateAvatar(formData)
            .then(res => {
              this.$message({ showClose: true, message: this.$t('头像上传成功'), type: "success" });
              this.getuserInfo();
            })
            .catch(error => {
              this.$message({ showClose: true, message: this.$t('头像上传失败'), type: "error" });
            });
        }
      }
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    toOrder() {
      this.$router.push("order");
    },
    nameBlur() {
      if (!this.username) return (this.nameActive = false);
    },
    emailBlur() {
      if (!this.userEmail) return (this.emailActive = false);
    },
    phoneBlur() {
      if (!this.userPhone) return (this.phoneActive = false);
    },
    asstIdBlur() {
      if (!this.emp_id) return (this.asstIdActive = false);
    },
    officalIdBlur() {
      if (!this.officialIdValue) return (this.officalIdActive = false);
    },
    handleSwitchChange(emp_id, value) {
      if (!emp_id) return this.$message.error('Assistant ID 不能为空');
      updateBaseUsage({
        "emp_id": emp_id,
        "enable": value
      }).then(res => {
        if (res.data.status === 'success') {
          if (value)
            this.$message.success(this.$t('绑定成功'));
          else
            this.$message.success(this.$t('解绑成功'));
        }
        if (res.data.status === 'error') {
          if (res.data.msg === '该助手未激活')
            return this.$message.error(this.$t('该助手未激活'))
          if (res.data.msg === '请输入正确的AI员工ID')
            return this.$message.error(this.$t('请输入正确的AI员工ID'))
        }
        if (this.bases.length) { this.emp_id = null }
        this.getuserInfo()
      }).catch(err => {
        if (value)
          this.$message.error(this.$t('绑定失败'));
        else
          this.$message.error(this.$t('解绑失败'));
      })
    },
    handleSwitchChangeAppID(officialIdValue, value) {
      if (this.app_id) return;
      if (!officialIdValue && !this.app_id) return this.$message.error('微信公众号不能为空');
      wechatQrcode({
        "app_id": this.app_id ? "" : officialIdValue,
      }).then(res => {
        if (res.data.status === 'success') {
          // 获取内存中的userData 并自定义路由传参
          let userData = localStorage.getItem("userData")
          // 定义原始URL
          let originalUrl  = res.data.data;
          // 使用split方法将URL按照'/userInfo'进行分割
          const urlParts = originalUrl.split('/userInfo');
          // 将分割后的URL重新组合并添加'?userData'
          const newUrl = urlParts[0] + '/userInfo?userData='+ userData + urlParts[1];

          console.log(newUrl);
          window.location.href = 'https://www.qimiaoren.com/wechat-qrcode/accredit.html?url=' + newUrl;
          return;
        }
        if (res.data.status === 'error') {
          return this.$message.error(res.data.msg);
        }
        if (!this.app_id) { this.officialIdValue = '' }
        this.getuserInfo()
      }).catch(err => {
        this.$message.error(this.$t('授权失败'));
      })
    },
    toGPTadmin() {
      let link = this.uploadUrl.replace(/\/[^/]*$/, '') + '/gpt-admin/';
      console.log(link)
      window.open(link, "_blank");
    },
    toWeChatSetting() {
      let link = 'https://mp.weixin.qq.com/'
      window.open(link, "_blank");
    },
    bindEmail() {
      if (!this.userEmail) {
        return (this.$message.error(this.$t("邮箱不能为空")));
      }
      else {
        checkUser({
          username: this.userEmail
        }).then(res => {
          /**regMethod为注册格式 -1为不合规 1为邮箱 2为手机  */
          if (res.data["regMethod"] === -1) {
            return this.$message.error(this.$t("填写格式有误"));
          }
          if (res.data["checkOrNot"]) {
            this.$message.error(this.$t("该邮箱已被占用"));
          }
          else {
            let emailPlaceholder = res.data["regMethod"]
            this.$router.push({
              name: 'bindEmail', query: {
                userEmail: this.userEmail,
                emailPlaceholder
              }
            })
          }
        });
      }
    },
    bindPhone() {
      if (!this.userPhone) {
        return (this.$message.error(this.$t("电话号码不能为空")));
      }
      else {
        checkUser({
          username: this.userPhone
        }).then(res => {
          /**regMethod为注册格式 -1为不合规 1为邮箱 2为手机  */
          if (res.data["regMethod"] === -1) {
            return this.$message.error(this.$t("填写格式有误"));
          }
          if (res.data["checkOrNot"]) {
            this.$message.error(this.$t("该号码已被占用"));
          }
          else {
            let emailPlaceholder = res.data["regMethod"]
            this.$router.push({
              name: 'bindPhone', query: {
                userPhone: this.userPhone,
                emailPlaceholder
              }
            })
          }
        });
      }
    }
  },
  watch: {
    userAvatar(newVal) {
      this.srcList = []
      this.srcList.push(newVal)
    }
  }
}
</script>
<style scoped lang="less">
.userInfo {
  // margin-left calc(50vw - 686px)
  overflow: hidden;
  height: 100vh;
  padding: 20px;
  display: flex;
  flex: 1;

  // margin -8px auto
  .user {
    // margin-left 212px
    /**白底黑字 */
    color: #333;
    font-size: 22px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    flex-direction: column;
    align-items: flex-start;
    /**绿底白字
    user-profile-setting-shadow: 0 2px 4px 0 rgba(0,0,0,.12), 0 0 6px 0 rgba(0,0,0,.04);
    background-color: #0fa47f
    color #fff
    */
  }

  .icon,
  .icon-1,
  .top {
    font-size: 2rem;
    position: fixed;
    top: 3rem;
    z-index: 10;
    color: #000;
    background-color: #fff;
  }

  .icon {
    left: 1.5rem;
  }

  .icon-1 {
    right: 1.5rem;
  }

  .top {
    top: 2rem;
  }

  .top {
    display: flex;
    align-items: center;

    .header {
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background-color: #10a37f;
      border-radius: 50%;
      color: #eee;
    }

    .header {
      display: inline-block;
      font-size: 2.5rem;
      margin: 0 10px 0 0;
    }

    .username {
      font-size: 32px;
      line-height: 40px;
      margin: 0 auto;
      display: inline-block;
      font-weight: 700;
    }
  }

  .number {
    font-size: 16px;
  }

  .secure {
    width: 100%;
    line-height: 1.5;

    .user-profile-setting {
      margin: 17px 0;

      // width: 340px;
      .title {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
      }

      .caption {
        font-size: 14px;
        font-weight: 400;
        color: #7E8081;
        margin-top: 8px;
      }

      .mouseHead {
        text-decoration: underline;
        color: #437eb4;
      }

      .knowledgebase-item {
        font-size: 14px;
        text-indent: .5rem;
        margin-top: 1rem;
      }

      input {
        font-size: 14px;
        height: 36px;
        padding: 6px 12px;
        max-width: 340px;
        -webkit-box-shadow: 0 0 0px 1000px white inset;
        border-radius: 8px;
        background: #FFFFFF;
        border: 1px solid #E4E8EB;
        border-radius: 3px;
      }

      input:focus {
        outline: none;
        border: 2px solid #10a37f;
      }

      input::-webkit-input-placeholder {
        margin-left: 10%;
        text-indent: 20px;
      }

      input::placeholder {
        text-indent: 0px;
        /* 调整 placeholder 与左侧的间距 */
      }

      .disabled {
        cursor: not-allowed;
        background-color: #f7f7f8;
        color: #acacbe;
      }

      .write {
        .continue {
          padding: 6px 0;
          background-color: #10a37f;
          color: white;
          border: 0px;
          outline: none;
          border-radius: 8px;
          font-size: 14px;
          height: 18px;
          line-height: 18px;
          width: 340px;
          margin-bottom: 3rem;
          box-sizing: content-box;
        }
      }

      .save {
        width: 56px;
        display: flex;
        align-items: center;
        justify-content: center;

        .btn {
          padding: 6px 0;
          background-color: #10a37f;
          color: white;
          border: 0px;
          outline: none;
          border-radius: 3px;
          font-size: 14px;
          height: 24px;
          line-height: 24px;
          width: 100% !important;
          // margin-bottom: 3rem;
          box-sizing: content-box;
        }

        .btn:hover {
          background-color: #1a7f64;
        }

        .btn:active {
          width: 56px;
          box-shadow: 0 0 0 3px #b7e3d8;
        }

        .content_label_btn_default {
          background-color: #eff0f2;
          color: #858585;
          border-color: #e4e8eb;
        }

        .content_label_btn_default:hover {
          background-color: #eff0f2;
          color: #858585;
          border-color: #e4e8eb;
          cursor: not-allowed;
        }
      }

      .previewIcon {
        height: 30rem;
        background-color: #f3f3f3;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .inputIcon {
      margin-top: 8px;
      position: relative;

      >div {
        display: flex;

        >.user-profile-setting {
          margin: 0;
          margin-left: calc(0.3rem + 1px);
          margin-left: 35px;
          /**微信风格 */
        }
      }

      .continue {
        line-height: 36px;
        height: 36px;
        border: 1px solid #c5c5d2;
        border-radius: 8px;
      }

      .continue-01 {
        margin-left: 5px;
        display: flex;
        margin-bottom: 3rem;
      }

      .iconfonts {
        padding: 10px;
        cursor: pointer;
        font-size: 12px;
        position: absolute;
        top: 50%;
        transform: translate(-100%, -50%);
      }

      .tips {
        display: inline-flex;
        align-items: center;
        font-size: 16px;
        line-height: 36px;
        height: 36px;

        >i {
          margin: 0 3px 0 10px;
        }
      }

      .success {
        color: #10a37f;
      }

      .error {
        color: #d24726;
      }

      i:hover {
        cursor: pointer;
      }
    }

    .avatar {
      display: flex;
      width: 340px;
      justify-content: space-between;

      .image,
      .imageDefault {
        width: 150px;
        height: 150px;
        border-radius: 6px;
        border: 1px solid #c5c5d2;
        line-height: 150px;
        text-align: center;
      }

      .imageDefault {
        line-height: 150px;
        text-align: center;
        color: #fff;
        background-color: #10a37f;
      }
    }
  }

  .inner {
    overflow-y: scroll;
    flex: 1;
    border: 1px solid #ebeef0;
    padding: 38px 125px 0 125px;
    line-height: 1.6;
    font-weight: 500;
    font-size: 14px;

    button {
      background-color: #fbfbfb;
      border: 1px solid #e4e8eb;
      color: #353535;
      margin: 20px 0px;
      padding: 0 22px;
      min-width: 54px;
      line-height: 2.42857143;
      vertical-align: middle;
      text-align: center;
      border-radius: 3px;
      font-size: 14px;
      cursor: pointer;
      border-width: 1px;
      border-style: solid;
      -webkit-box-sizing: content-box;
      box-sizing: content-box;
    }

    button:hover {
      background-color: #eff0f2;
      color: #858585;
    }

    .content_title {
      font-size: 16px;
      color: #1D1D26;
      margin-bottom: 8px;
      font-weight: 500;
    }

    .content_tip {
      font-size: 14px;
      font-weight: 400;
      color: #9A9A9A;
      margin-bottom: 32px;
    }

    .content_form {
      color: #9A9A9A;

      .content_list {
        color: #9A9A9A;
        display: flex;
        margin-bottom: 30px;

        .content_label {
          font-weight: 400;
          color: #353535;
          margin: 6.8px 13px 0 0;
          width: 70px;
          min-width: 70px;
        }

        .content_label_controls {

          .content_label_input {
            width: 300px;
            height: 36px;
            border: 1px solid #e4e8eb;
            border-radius: 5px;
            padding: 10px;
            color: #353535;
          }

          .content_label_input:focus {
            outline: none;
            border-radius: 5px;
            border: 1px solid #10a37f;
          }

          .content_label_input::-webkit-input-placeholder {
            margin-left: 10%;
            text-indent: 20px;
          }

          .content_label_input::placeholder {
            text-indent: 0px;
            /* 调整 placeholder 与左侧的间距 */
          }

          .caption {
            font-size: 12px;
            font-weight: 400;
            margin-top: 8px;

            .mouseHead {
              text-decoration: underline;
              color: #437eb4;
            }
          }

          .content_form_tip {
            margin: 8px auto 0;
            font-weight: 400;
            font-size: 12px;
          }
        }

        .content_upload {
          display: flex;
          justify-content: space-between;

          .form_upload_left {
            height: auto;

            .forms {
              display: flex;

              .content_label_input {
                margin-right: 30px;
              }

              .content_label_btn {
                margin: 0;
                background-color: #0fa47f;
                color: #fff;
                margin-right: 45px;
              }

              .content_label_btn:hover {
                background-color: #1a7f64;
              }

              .content_label_btn_default {
                background-color: #eff0f2;
                color: #858585;
                border-color: #e4e8eb;
              }

              .content_label_btn_default:hover {
                background-color: #eff0f2;
                color: #858585;
                border-color: #e4e8eb;
                cursor: not-allowed;
              }
            }
          }

          .disabled {
            cursor: not-allowed;
            background-color: #f7f7f8;
            color: #acacbe;
          }


          .form_upload_line {
            width: 1px;
            background-color: #E4E8EB;
            margin-right: 40px;
          }

          .form_upload_right {

            .image,
            .imageDefault {
              width: 108px;
              height: 108px;
              border-radius: 6px;
              line-height: 108px;
              text-align: center;
              margin-top: 20px;
            }

            .imageDefault {
              line-height: 108px;
              text-align: center;
              color: #fff;
              background-color: #10a37f;
            }
          }
        }

        .inputIcon {
          margin-top: 8px;
          position: relative;

          >div {
            width: 130px;
            height: 130px;
            display: flex;

            /deep/.el-upload {
              height: 130px !important;
            }

            >.user-profile-setting {
              margin: 0;
              margin-left: calc(0.3rem + 1px);
              margin-left: 35px;
              /**微信风格 */
            }
          }

          .continue {
            line-height: 36px;
            height: 36px;
            border: 1px solid #c5c5d2;
            border-radius: 8px;
          }

          .continue-01 {
            margin-left: 5px;
            display: flex;
            margin-bottom: 3rem;
          }

          .iconfonts {
            padding: 10px;
            cursor: pointer;
            font-size: 12px;
            position: absolute;
            top: 50%;
            transform: translate(-100%, -50%);
          }

          .tips {
            display: inline-flex;
            align-items: center;
            font-size: 16px;
            line-height: 36px;
            height: 36px;

            >i {
              margin: 0 3px 0 10px;
            }
          }

          .success {
            color: #10a37f;
          }

          .error {
            color: #d24726;
          }

          i:hover {
            cursor: pointer;
          }
        }

        .avatar {
          display: flex;
          width: 300px;
          justify-content: space-between;

          .image,
          .imageDefault {
            width: 130px;
            height: 130px;
            border-radius: 6px;
            border: 1px solid #c5c5d2;
            line-height: 130px;
            text-align: center;
          }

          .imageDefault {
            font-size: 22px;
            line-height: 130px;
            text-align: center;
            color: #fff;
            background-color: #10a37f;
          }
        }
      }
    }
  }

  .preview {
    background-color: #f3f3f3;
    position: relative;

    .title,
    .link {
      font-size: 16px;
      color: #000;
      text-align: center;
    }

    .link>.logo {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      width: 150px;
      border-radius: 50%;
      transition: transform 0.3s ease;
    }
    .link > .logo:hover {
      transform: translate(-50%, -50%) scale(1.05);
    }
  }

  /deep/ .el-upload__input {
    display: none !important;
  }
}
</style>
<style scoped lang="less">
@media screen and (max-width: 1800px) {
  .userInfo {
    margin-left: 0;
    .inner {
      padding: 38px 0 0 0;
      .content_title {
          width: 383px;
          margin: auto;
        }
      .content_form {
        .content_list {
          justify-content: center;
          .content_label_controls {
            .content_label_input {
              width: 200px;
            }
          }
          .content_upload{ .form_upload_left {.forms .content_label_btn{
            padding: 0 7.5px;
            margin-right: 0;
          }}}
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .userInfo {
    height: 100vh;
    padding: 0;
    display: block;

    .user {
      margin: 0;
      /**白底黑字 */
      color: #333;
      font-size: 22px;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100vh;

      /**绿底白字
      user-profile-setting-shadow: 0 2px 4px 0 rgba(0,0,0,.12), 0 0 6px 0 rgba(0,0,0,.04);
      background-color:#0fa47f
      border-radius 0
      color #fff*/
      .top {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: .5rem;
      }

      .titleTop {
        width: 100%;
        height: 6rem;
        background-color: #fff;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 3.8rem 1.5rem;
        justify-content: center;

        .icon,
        .icon-1,
        .top {
          // height: 7rem;
        }
      }
    }

    .secure {
      padding: 4rem 0 7rem 0;

      .user-profile-setting {
        margin: 17px auto;
      }

      .inputIcon {
        .iconfonts {
          right: 2vw;
          transform: translate(0%, -50%) !important;
        }
      }
    }

    .header {
      width: 5rem;
      height: 5rem;
      line-height: 5rem;
      font-size: 2.5rem;
      text-align: center;
      background-color: #10a37f;
      border-radius: 50%;
      color: #eee;
    }

    .header,
    .username {
      display: inline-block;
      font-size: 2.5rem;
    }
  }
}
</style>
